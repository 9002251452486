import classNames from "classnames";
import Link from "next/link";
import PropTypes from "prop-types";
import React, { useContext } from "react";

import JoinFlowCTA from "@/components/JoinFlowCTA";
import AuthWrapper from "@/components/auth-wrapper";
import LoggedInOutCta from "@/components/buttons/logged-in-out-toggle-cta";
import PrimaryCta from "@/components/buttons/primary-cta";
import { ctaLarge } from "@/components/buttons/primary-cta/index.module.scss";
import { LEAD_FLOW_CTA_SOURCE } from "@/components/seo-and-analytics/adobe-data-layer/acdl-data-layer/lead-flow";
import { ROUTES } from "@/constants";
import { DigitalDataLayerContext } from "@/context/DigitalDataLayer";

import styles from "./HomepageHero.module.scss";

const LeftUserInteraction = ({
  className,
  userInteraction,
  primaryCtaStyles,
  positionContent,
}) => {
  const {
    cta: ctaStyles,
    ctaWrapper,
    borderlessButton,
    transparentButton,
  } = styles;
  const colorScheme = userInteraction[0].fields.colorScheme;

  let justifyPosition;
  let styleObj = {
    margin: `0 ${positionContent === "center" ? "auto" : ""}`,
    marginLeft: "auto",
  };

  switch (positionContent) {
    case "center":
      justifyPosition = "justify-content-center";
      break;

    case "right":
      justifyPosition = "justify-content-end";
      break;

    case "left":
      justifyPosition = "justify-content-start";
      delete styleObj.marginLeft;
      break;

    case "unset":
      styleObj = {};
      break;

    default:
      break;
  }

  const { setLeadFlowInfo } = useContext(DigitalDataLayerContext);

  const handleOnClick = (leadFlow, text) => {
    setLeadFlowInfo({
      ctaEvent: `${text} | ${LEAD_FLOW_CTA_SOURCE.HERO}`,
      data: leadFlow.fields,
      isEmbedded: false,
    });
  };

  return (
    <div
      className={classNames(
        className,
        "col-lg-8",
        "p-0",
        colorScheme === "light" && styles.whiteBtn,
        colorScheme === "dark" && styles.blackBtn
      )}
      style={styleObj}
    >
      <div className={`${ctaWrapper} ${justifyPosition}`}>
        {userInteraction[0].fields.heroCta.map((cta, index) => {
          const { text, url, leadFlow, isJoinFlow } = cta.fields;
          const { id } = cta.sys.contentType.sys;

          if (isJoinFlow) {
            return (
              <JoinFlowCTA
                className={`
                ${ctaStyles} ${ctaLarge} ${styles.transparentButton}
                ${primaryCtaStyles ? primaryCtaStyles : ""}
                ${index === 1 ? borderlessButton : ""}
              `}
                eventSource={LEAD_FLOW_CTA_SOURCE.HERO}
                key={id}
                label={text}
                originalUrl={url}
              />
            );
          }

          if (id === "componentLinkMemberToggle") {
            return <LoggedInOutCta key={id} {...cta.fields} />;
          }

          if (leadFlow) {
            return (
              <React.Fragment key={leadFlow.sys.id}>
                <AuthWrapper renderOnUserSession={false}>
                  <Link href={ROUTES.NEW_LEAD_FLOW_ROUTE}>
                    <a>
                      <PrimaryCta
                        key={id}
                        label={text}
                        onClick={() => handleOnClick(leadFlow, text)}
                        style={`${ctaStyles} ${styles.transparentButton} ${primaryCtaStyles}`}
                      />
                    </a>
                  </Link>
                </AuthWrapper>
              </React.Fragment>
            );
          }

          return (
            <PrimaryCta
              key={id}
              label={text}
              style={`${ctaStyles} ${transparentButton} ${
                index === 1 ? borderlessButton : ""
              }`}
              url={url}
            />
          );
        })}
      </div>
    </div>
  );
};

LeftUserInteraction.propTypes = {
  className: PropTypes.string,
  positionContent: PropTypes.string.isRequired,
  primaryCtaStyles: PropTypes.string,
  userInteraction: PropTypes.array.isRequired,
};

export default LeftUserInteraction;
