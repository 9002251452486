import PropTypes from "prop-types";
import React, { useContext } from "react";

import { UserContext } from "@/context/User";

const AuthWrapper = ({ renderOnUserSession, children }) => {
  const context = useContext(UserContext);
  const hasSession = !!(
    context &&
    context.initialized &&
    context.session &&
    context.session.user
  );

  if (
    (renderOnUserSession && hasSession) ||
    (!renderOnUserSession && !hasSession)
  ) {
    return children;
  }

  return <div className="auth-wrapper-empty d-none"></div>;
};

AuthWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  renderOnUserSession: PropTypes.bool,
};

AuthWrapper.defaultProps = {
  renderOnUserSession: false,
};

export default AuthWrapper;
