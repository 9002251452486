import PropTypes from "prop-types";
import React, { useContext } from "react";

import { baseEnvironmentConfig } from "@/api/constants";
import LinkArrowBlack from "@/assets/icons/link-arrow-black.svg";
import LinkArrowWhite from "@/assets/icons/link-arrow-white.svg";
import { ACDL_JOIN_FLOW_TRACKING } from "@/components/seo-and-analytics/adobe-data-layer/acdl-data-layer/join-flow";
import { DigitalDataLayerContext } from "@/context/DigitalDataLayer";

const JoinFlowCTA = ({
  label,
  onClick,
  facilityId = "",
  className = "",
  eventSource,
  isArrow = false,
  isThemeLight = false,
  originalUrl,
}) => {
  const queryParams = originalUrl.split("?")[1];
  const url = `/join/step1${facilityId ? `/${facilityId}` : ""}${
    queryParams ? `?${queryParams}` : ""
  }`;
  const { setNewDataLayer } = useContext(DigitalDataLayerContext);

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    const layer = ACDL_JOIN_FLOW_TRACKING.joinCtaEvent(label, eventSource);
    setNewDataLayer(layer);
  };

  return (
    <a
      className={`${className}`}
      data-testid={baseEnvironmentConfig.testIds.joinFlowCta}
      href={url}
      onClick={handleClick}
    >
      <span>{label} </span>
      {isArrow && (isThemeLight ? <LinkArrowBlack /> : <LinkArrowWhite />)}
    </a>
  );
};

JoinFlowCTA.propTypes = {
  className: PropTypes.string,
  eventSource: PropTypes.string.isRequired,
  facilityId: PropTypes.string,
  isArrow: PropTypes.bool,
  isThemeLight: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  originalUrl: PropTypes.string.isRequired,
};

export default JoinFlowCTA;
