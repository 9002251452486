import { withRouter } from "next/router";
import PropTypes from "prop-types";
import querystring from "query-string";
import React from "react";
import { Cookies } from "react-cookie";

import { baseEnvironmentConfig } from "@/api/constants";
import { COOKIES } from "@/constants";

import { loggedInOutCta } from "./index.module.scss";

const LoggedInOutCta = ({ styles, loggedInText, loggedOutText, router }) => {
  const moduleStyles = { ...styles };

  const returnUrl = router.asPath;
  const params = querystring.stringify({ returnUrl });

  const loginUrl = baseEnvironmentConfig.session.defaultLoginUrl;
  const logoutUrl = baseEnvironmentConfig.session.defaultLogoutUrl;

  const url = `${loginUrl}?${params}`;

  const bavToken = new Cookies().get(COOKIES.USER_SESSION_ACCESS_TOKEN.name);

  return (
    <a
      className={loggedInOutCta}
      href={bavToken ? logoutUrl : url}
      style={moduleStyles}
    >
      {bavToken ? loggedOutText : loggedInText}
    </a>
  );
};

export default withRouter(LoggedInOutCta);

LoggedInOutCta.propTypes = {
  loggedInText: PropTypes.string.isRequired,
  loggedOutText: PropTypes.string.isRequired,
  router: PropTypes.object.isRequired,
  styles: PropTypes.object,
};
